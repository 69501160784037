import React from 'react'

import Typography from 'shared/components/Typography'

import styles from './SupportWidget.module.css'

export type ChatItemProps = {
  name: string
  link: string
  icon: React.ReactElement
}

export const ChatItem: React.FC<ChatItemProps> = ({ icon, link, name }) => (
  <a href={link} className={styles.chats_item} target="_blank" rel="noreferrer">
    {icon}
    <Typography color="white" component="span" size="l" className={styles.chats_item_text}>
      {name}
    </Typography>
  </a>
)
