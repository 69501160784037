import React from 'react'

import TypographyLink from 'shared/components/TypographyLink'

import styles from './Footer.module.css'

export const FooterDocuments = () => (
  <div className={styles.documents}>
    <TypographyLink to="/terms" uppercase weight="light">
      пользовательское соглашение
    </TypographyLink>
    <TypographyLink to="/privacy" uppercase weight="light">
      Политика конфиденциальности
    </TypographyLink>
    <TypographyLink to="/contacts" uppercase weight="light">
      Реквизиты
    </TypographyLink>
  </div>
)
