import React from 'react'
import cn from 'clsx'

import Header from 'shared/core/components/Header'
import { Footer } from 'shared/core/components/Footer'
import { SupportWidget } from 'shared/components/SupportWidget'

import styles from './BaseLayout.module.css'

type Props = {
  className?: string
}

export const BaseLayout: React.FC<Props> = ({ className, children }) => (
  <div className={cn(styles.layout, className)}>
    <Header />
    <div className={styles.main}>{children}</div>
    <Footer />
    <SupportWidget />
  </div>
)
