import React from 'react'
import cn from 'clsx'

import capitalize from 'shared/utils/capitalize'

import styles from './Spinner.module.css'

type Props = {
  pieceCount?: number
  duration?: number
  size?: 's' | 'm'
  color?: 'primary' | 'white'
  className?: string
}

export const Spinner: React.FC<Props> = ({
  duration = 0.8,
  pieceCount = 8,
  color = 'primary',
  size = 'm',
  className,
}) => {
  const sizeCn = `size${capitalize(size)}`

  const sliceCn = cn(styles.slice, styles[color], styles[`${sizeCn}__slice`])
  const spinnerCn = cn(className, styles.spinner, styles[`${sizeCn}__spinner`])
  return (
    <div className={spinnerCn}>
      {Array(pieceCount)
        .fill(0)
        .map((_, i) => {
          const rotate = (i * 360) / pieceCount
          const delay = (i + 1) * (duration / pieceCount) - duration
          return (
            <div
              key={i.toString()}
              className={sliceCn}
              style={{
                transform: `rotate(${rotate}deg)`,
                animationDelay: `${delay}s`,
                animationDuration: `${duration}s`,
              }}
            />
          )
        })}
    </div>
  )
}
