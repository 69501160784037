import React, { forwardRef, useState, memo, useEffect } from 'react'
import cn from 'clsx'

import useCombinedRefs from 'shared/hooks/useCombinedRefs'
import capitalize from 'shared/utils/capitalize'

import { Spinner } from '../Spinner'

import styles from './Input.module.css'
import { InputProps } from './types'

const Input = forwardRef<HTMLInputElement, InputProps>(function Input(props, ref) {
  const {
    className,
    size = 'm',
    autoComplete = 'on',
    type = 'text',
    fullWidth = false,
    error = false,
    loading = false,
    withoutBorder = false,
    testId,
    iconLeft: IconLeft,
    iconRight: IconRight,
    onIconRightClick,
    disabled,
    ...rest
  } = props

  const combinedRef = useCombinedRefs<HTMLInputElement>(ref)

  const [focus, setFocus] = useState<boolean>(false)

  const handleClickOutside = (event: MouseEvent) => {
    const { current } = combinedRef
    if (current && !current.contains(event.target as Node) && focus) {
      setFocus(false)
    }
  }

  const iconRightClickHandler: React.MouseEventHandler<SVGSVGElement> = e => {
    if (onIconRightClick) {
      onIconRightClick(e)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const handleFocus = (): void => {
    setFocus(true)
  }

  const handleBlur = (): void => {
    setFocus(false)
  }

  const sizeCn = `size${capitalize(size)}`
  const inputWrapperCn = cn(
    styles.wrapper,
    styles[sizeCn],
    {
      [styles.wrapper_fullWidth]: fullWidth,
      [styles.wrapper_focus]: focus,
      [styles.wrapper_border]: !focus,
      [styles.wrapper_disabled]: disabled,
      [styles.wrapper_error]: error,
      [styles.wrapper_withoutBorder]: withoutBorder,
    },
    className,
  )

  const iconCn = cn(styles.icon, styles[`size${capitalize(size)}__icon`])

  return (
    <div className={inputWrapperCn} onFocus={handleFocus} onBlur={handleBlur} role="searchbox">
      {IconLeft && <IconLeft className={cn(iconCn, styles.icon__left)} />}
      <input
        className={styles.input}
        type={type}
        data-test={testId}
        disabled={disabled}
        ref={combinedRef}
        autoComplete={autoComplete}
        {...rest}
      />
      {IconRight && <IconRight onClick={iconRightClickHandler} className={cn(iconCn, styles.icon__right)} />}
      {loading && <Spinner size="s" className={styles.loading} />}
    </div>
  )
})

export default memo(Input)
