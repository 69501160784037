import React from 'react'

import { FooterLogo } from './FooterLogo'
import styles from './Footer.module.css'
import { FooterSocials } from './FooterSocials'
import { FooterDocuments } from './FooterDocuments'

export const Footer = () => (
  <footer className={styles.footer}>
    <FooterSocials />
    <FooterDocuments />
    <FooterLogo />
  </footer>
)
