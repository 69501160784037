import React, { useState } from 'react'
import cn from 'clsx'

import { ReactComponent as QuestionIcon } from 'shared/assets/icons/question_support.svg'
import { ReactComponent as TgIcon } from 'shared/assets/icons/socials/tg.svg'
import { ReactComponent as VkIcon } from 'shared/assets/icons/socials/vk.svg'

import styles from './SupportWidget.module.css'
import { ChatItem, ChatItemProps } from './ChatItem'

type Props = {
  className?: string
}

const chats: ChatItemProps[] = [
  {
    icon: <VkIcon className={styles.chats_icon} />,
    link: 'https://vk.com/im?media=&sel=-214463252',
    name: 'Связь с оператором',
  },
  {
    icon: <TgIcon className={styles.chats_icon} />,
    link: 'https://t.me/FuttyCoinSupportBot',
    name: 'Чат-бот',
  },
]

export const SupportWidget: React.FC<Props> = ({ className }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const onMouseEnter = () => {
    setIsOpen(true)
  }

  const onMouseLeave = () => {
    setIsOpen(false)
  }

  const widgetCn = cn(className, styles.widget, {
    [styles.widget_opened]: isOpen,
  })

  const iconCn = cn(styles.icon, {
    [styles.icon_opened]: isOpen,
  })

  const chatsCn = cn(styles.chats, {
    [styles.chats_opened]: isOpen,
  })

  return (
    <div className={widgetCn} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <div className={styles.content}>
        <div className={iconCn}>
          <QuestionIcon className={styles.icon_question} />
        </div>
        <div className={chatsCn}>
          {chats.map(props => (
            <ChatItem key={props.name} {...props} />
          ))}
        </div>
      </div>
    </div>
  )
}
