import React, { useCallback, useState } from 'react'
import { Placement } from '@popperjs/core'
import { usePopper } from 'react-popper'
import cn from 'clsx'

import Portal from 'shared/components/Portal'

import styles from './Tooltip.module.css'

interface TooltipProps {
  title?: string
  children: React.ReactElement
  className?: string
  classNameContent?: string
  positions?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight'
  offsetY?: number
  offsetX?: number
  placement?: Placement
  disabled?: boolean
}

const Tooltip: React.FC<TooltipProps> = ({
  title,
  children,
  className,
  classNameContent,
  placement = 'bottom',
  disabled = false,
  offsetX = 0,
  offsetY = 20,
}): React.ReactElement => {
  const [visible, setVisible] = useState<boolean>(false)
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)

  const { styles: stylesPopper, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    strategy: 'fixed',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [offsetX, offsetY],
        },
      },
    ],
  })

  const handleMouseEnter = useCallback(() => {
    setVisible(true)
  }, [])

  const handleMouseLeave = useCallback(() => {
    setVisible(false)
  }, [])

  if (!title) {
    return children
  }

  return (
    <>
      {React.cloneElement(children, {
        ref: setReferenceElement,
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
      })}

      {!disabled && visible && (
        <Portal>
          <div
            ref={setPopperElement}
            className={cn(styles.tooltip, className)}
            style={stylesPopper.popper}
            {...attributes.popper}
          >
            <div className={cn(styles.tooltip__content, classNameContent)}>{title}</div>
          </div>
        </Portal>
      )}
    </>
  )
}

export default Tooltip
