import React from 'react'
import { Link } from 'react-router-dom'

import { ReactComponent as LogoIcon } from 'shared/assets/icons/logo.svg'

import styles from './Footer.module.css'

export const FooterLogo = () => (
  <Link to="/" className={styles.logo}>
    <LogoIcon className={styles.logo_icon} />
  </Link>
)
