import React from 'react'

import Typography from 'shared/components/Typography'

import styles from './Footer.module.css'

export type Socials = {
  name: string
  link: string
  icon: React.ReactElement
}

export const SocialItem: React.FC<Socials> = ({ icon, link, name }) => (
  <a href={link} className={styles.socials__item} target="_blank" rel="noreferrer">
    {icon}
    <Typography component="span" weight="light" size="s" className={styles.socials__item_text}>
      {name}
    </Typography>
  </a>
)
