import React from 'react'

import Typography from 'shared/components/Typography'

import { ReactComponent as TgIcon } from '../../../assets/icons/socials/tg.svg'
import { ReactComponent as VkIcon } from '../../../assets/icons/socials/vk.svg'

import styles from './Footer.module.css'
import { SocialItem, Socials } from './SocialItem'

const socials: Socials[] = [
  {
    icon: <VkIcon className={styles.socials__icon} />,
    link: 'https://vk.com/im?media=&sel=-214463252',
    name: 'Онлайн-чат',
  },
  { icon: <TgIcon className={styles.socials__icon} />, link: 'https://t.me/FuttyCoinSupportBot', name: 'Чат-бот' },
]

export const FooterSocials = () => (
  <div className={styles.socials}>
    <Typography size="s" uppercase className={styles.socials__title}>
      Поддержка
    </Typography>
    {socials.map(({ icon, link, name }) => (
      <SocialItem key={link} icon={icon} link={link} name={name} />
    ))}
  </div>
)
