import React, { forwardRef, useRef } from 'react'
import cn from 'clsx'

import useCombinedRefs from 'shared/hooks/useCombinedRefs'
import capitalize from 'shared/utils/capitalize'

import { Spinner } from '../Spinner'

import { ButtonColor, ButtonSize } from './constants'
import { ButtonProps } from './types'
import styles from './Button.module.css'

const Button = forwardRef<HTMLButtonElement, ButtonProps>(function ButtonBase(props, ref) {
  const {
    icon: Icon,
    color = ButtonColor.primary,
    size = ButtonSize.m,
    disabled = false,
    outline = false,
    fullWidth = false,
    circle = false,
    short = false,
    uppercase = true,
    loading = false,
    children,
    className,
    onClick,
    tabIndex = 0,
    type = 'button',
    ...rest
  } = props

  const innerRef = useRef<HTMLButtonElement>(null)
  const combinedRef = useCombinedRefs<HTMLButtonElement>(ref, innerRef)

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event): void => {
    if (disabled || !onClick) return

    onClick(event)
  }

  const versionCn = outline ? 'outline' : 'fill'
  const colorCn = `${versionCn}_${color}`
  const sizeCn = circle ? `size${capitalize(size)}__circle` : `size${capitalize(size)}`
  const disabledCn = `${versionCn}_disabled`
  const iconCn = cn(styles.icon, styles[`size${capitalize(size)}__icon`], { [styles.icon__circle]: circle })
  const spinnerColor = outline ? 'primary' : 'white'

  const buttonCn = cn(className, styles.button, styles[versionCn], styles[sizeCn], {
    [styles.outline]: outline,
    [styles.fullWidth]: fullWidth,
    [styles[colorCn]]: !disabled,
    [styles.disabled]: disabled,
    [styles[disabledCn]]: disabled,
    [styles.circle]: circle,
    [styles.button_short]: short,
    [styles.uppercase]: uppercase,
  })

  return (
    <button
      type={type}
      className={buttonCn}
      ref={combinedRef}
      tabIndex={disabled ? -1 : tabIndex}
      onClick={handleClick}
      {...rest}
    >
      <div className={styles.button_content}>
        {Icon && <Icon className={iconCn} />}
        {children}
      </div>
      {loading && <Spinner color={spinnerColor} size="s" className={styles.loading} />}
    </button>
  )
})

export default Button
