export enum ButtonSize {
  s = 's',
  m = 'm',
  l = 'l',
}

export enum ButtonColor {
  primary = 'primary',
  lightBlue = 'lightBlue',
  gray = 'gray',
  lightGray = 'lightGray',
  darkGray = 'darkGray',
  success = 'success',
  error = 'error',
}
